<template>
  <el-dialog :lock-scroll="true" v-model="show">

    <template v-slot:title>
      <div  style="border-bottom: #e0e0e0 1px solid;padding: 20px;">
        <div class="modal-title" >{{title}}</div>
      </div>
    </template>


    <template v-slot:footer>
      <div  style="border-top: #e0e0e0 1px solid;padding: 20px;display: flex;justify-content: space-between;">

        <el-button type="danger" plain @click="doCancel()">{{KT('cancel')}}</el-button>

        <el-button type="primary" @click="doSave()">{{KT('save')}}</el-button>
      </div>
    </template>


    <div style="padding: 20px;">
      <el-form label-width="120px" label-position="top">


        <el-form-item :label="KT('integrations.enable')" >
          <el-switch
              v-model="formData['enabled']"
              :inactive-text="KT('no')"
              :active-text="KT('yes')"
          >
          </el-switch>
        </el-form-item>

        <el-form-item v-for="input in integrationData.inputs" :key="input.key" :label="KT('integrations.'+integrationData.key+'.inputs.'+input.key)" >

            <el-switch v-if="input.type=='switch'"
                v-model="formData[input.key]"
                :inactive-text="KT('no')"
                :active-text="KT('yes')"
                       :inactive-value="0"
                       :active-value="1"
            >
            </el-switch>
          <el-input v-else :type="input.type" v-model="formData[input.key]" ></el-input>
        </el-form-item>

      </el-form>



    </div>






  </el-dialog>
</template>


<script setup>

import 'element-plus/es/components/input/style/css'
import 'element-plus/es/components/button/style/css'
import 'element-plus/es/components/switch/style/css'
import 'element-plus/es/components/select/style/css'
import 'element-plus/es/components/option/style/css'
import 'element-plus/es/components/dialog/style/css'
import 'element-plus/es/components/tab-pane/style/css'
import 'element-plus/es/components/tabs/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/checkbox/style/css'

import {
  ElButton,
  ElDialog, ElForm, ElFormItem, ElInput, ElSwitch,
} from "element-plus";


import {useStore} from 'vuex'

const store = useStore();


import {ref, defineExpose} from 'vue';



const title = ref('');

const show = ref(false);
const tab = ref('first');

const integrationData = ref({});
const formData = ref({});



import KT from '../../func/kt';

const doCancel = ()=>{
  show.value = false;
}

const doSave = ()=>{
  store.dispatch("integrations/save",{key: integrationData.value.key,data: formData.value});

  show.value = false;
}


const configureIntegration = (integration)=>{

  integrationData.value = integration;

  if(integration.settings == false){
    integration.settings = {attributes: {}};
    formData.value['enabled'] = true;
  }else{
    formData.value['enabled'] = integration.settings.attributes['enabled'];
  }




  integration.inputs.forEach((i)=>{
    // eslint-disable-next-line no-prototype-builtins
    formData.value[i.key] = (integration.settings.attributes.hasOwnProperty(i.key) && integration.settings.attributes[i.key])?integration.settings.attributes[i.key]:i.default;
  })

  title.value = KT('integrations.'+integration.key+'.title');
  tab.value = 'first';

  show.value = true;
}

defineExpose({
  configureIntegration
});



</script>

<style>

.el-select.el-select--large{
  width: 100%;
}

.el-dialog__header,.el-dialog__body,.el-dialog__footer{
  padding: 0px !important;
}

.el-dialog__footer{
  margin-top: 20px;
}

.el-tabs__nav-wrap{
  padding-left: 20px;
  padding-right: 20px;
}

.el-tabs__content{
  padding-left: 20px;
  padding-right: 20px;
}




</style>